






























































































































import { Component, Vue } from 'vue-property-decorator';
import { IFeedback } from '@/types/feedback';
import { IUser } from '@/types/user';
import Comment from '@/components/comments/Comment.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import SearchUsers from '@/components/SearchUsers.vue';
import { API } from '@/services/api';

@Component({
  components: {
    Comment,
    UserAvatar,
    SearchUsers,
  },
})
export default class FeedbackPage extends Vue {
  public comments: Array<IFeedback> = [];
  public users: IUser[] = [];
  public usersBySearch: IUser[] = [];
  public selectedUser = 0;
  public selectedComments: Array<[]> = [];
  public newStatus = false;
  public commentsList: Array<Array<IFeedback>> = [];
  public search = '';

  get commentsBySearch(): Array<IFeedback> {
    let result: Array<IFeedback> = [];
    if (!this.search) result = this.commentsList[this.selectedUser];
    else {
      const search = this.search.toLowerCase();
      result = this.commentsList[this.selectedUser].filter((comment) => {
        if (comment.comment.toLowerCase().includes(search)) return true;
        if (comment.metric.subsection.title.toLowerCase().includes(search)) return true;
        if (comment.updatedAt.includes(search)) return true;
        if (comment.createdAt.includes(search)) return true;
        if (String(comment.lessonId).includes(search)) return true;
        return false;
      });
    }

    if (!result) return [];
    return result.sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      if (aDate > bDate) return 1;
      if (aDate < bDate) return -1;
      return 0;
    });
  }

  async mounted() {
    this.comments = (await API.feedback.fetchAll()).data.map((comment) => ({
      ...comment,
      updatedAt: this.$formattingTimeDate(comment.updatedAt, ' '),
    }));

    this.users = (await API.users.getUsersWithComments()).data.map((user) => ({
      ...user,
      fullName: this.$getUserFullName(user),
    }));
    this.setCommentsList();
  }

  setCommentsList() {
    const list = [this.comments, ...this.users.map((user) => user.comments)] as Array<Array<IFeedback>>;
    this.commentsList = list;
  }

  openLessonPage({ id, section, subsection }: { id: string, section: string, subsection: string }) {
    this.$openPage(`/lesson/${id}/${section}/${subsection}`);
  }

  searchUsers(users: Array<IUser>) {
    this.usersBySearch = users;
    this.selectedUser = 0;
  }

  async changeCommentStatus(commentId: number, newStatus: boolean) {
    try {
      const { id, status } = (await API.feedback.updateStatus(commentId, !newStatus)).data;
      this.commentsList.forEach((arrayComments: IFeedback[]) => {
        arrayComments.forEach((comment: IFeedback) => {
          if (comment.id === id) comment.status = status;
        });
      });
    } catch (error: any) {
      this.$notify({
        type: 'error',
        content: error,
      });
    }
  }
}
