





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IUser } from '@/types/user';
import UserAvatar from '@/components/UserAvatar.vue';

@Component({ components: { UserAvatar } })
export default class SortButton extends Vue {
  @Prop() readonly users!: Array<IUser>

  public selectedUsers: Array<IUser> = [];
  isUpdating = false;

  @Watch('selectedUsers')
  watchSelectedUsers(val: Array<IUser>) {
    this.$emit('search', val);
  }

  remove(user: IUser) {
    const index = this.selectedUsers.findIndex(({ id }) => user.id === id);
    if (index >= 0) this.selectedUsers.splice(index, 1);
  }
}
