























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { API } from '@/services/api';
import { store } from '@/store';
import UserAvatar from '@/components/UserAvatar.vue';
import { IUser } from '@/types/user';

@Component({ components: { UserAvatar } })
export default class Comment extends Vue {
  @Prop() private readonly status!: boolean;
  @Prop() private readonly comment!: string;
  @Prop() private readonly id!: number;
  @Prop() private readonly createdAt!: string;
  @Prop() private readonly updatedAt!: string;
  @Prop() private readonly user!: IUser;

  public snackbar = false;
  private editable = false;
  private editedText: null | string = null;
  private newUpdatedAt: string | null = null;

  get text() {
    if (this.editedText === null) return this.comment;
    return this.editedText;
  }
  set text(val) {
    this.editedText = val;
  }

  get label() {
    const name = this.$getUserFullName(this.user);
    const created = this.$formattingTimeDate(this.createdAt);

    let updated = '';
    if (this.newUpdatedAt) updated = this.newUpdatedAt;
    else if (this.createdAt !== this.updatedAt) updated = this.updatedAt;
    if (updated) updated = ` — изменено ${this.$formattingTimeDate(updated)}`;

    return `${name} — ${created}${updated}`;
  }

  get isMyComment() {
    const { user } = store.auth.state;
    return user?.id === this.user.id;
  }

  get color() {
    if (this.editable && !this.text) return 'red';
    return this.editable ? this.$accent : 'white';
  }

  /**
   * Правило, ограничивающее длину сообщения
   */
  private rulesBroken = false;
  get rules() {
    return [
      (v: any) => {
        if (v.length <= 255) {
          this.rulesBroken = false;
          return true;
        }
        this.rulesBroken = true;
        return 'Максимум 255 символов';
      },
    ];
  }

  async editComment() {
    if (!this.editable) this.editable = true;
    else this.snackbar = true;
  }

  async saveComment() {
    try {
      const response = await API.feedback.update({
        id: this.id,
        comment: this.text,
      });
      if (response.data) {
        this.newUpdatedAt = response.data.updatedAt;
        this.editable = false;
      }
    } catch (error: any) {
      this.$notify({ type: 'error', content: error });
    }
  }

  async deleteComment() {
    try {
      const result = await API.feedback.delete(this.id);
      if (result) this.destroySelf();
    } catch (error: any) {
      this.$notify({ type: 'error', content: error });
    }
  }

  destroySelf() {
    this.$destroy();
    return this?.$el?.parentNode?.removeChild(this.$el);
  }
}
